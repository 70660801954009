<template>
  <div class="page">
    <el-row>
      <el-col :span="12">
        <h2>导入商品</h2>
        <el-form label-position="left" label-width="120px">
          <el-form-item label="导入类型">
            <el-radio-group v-model="type">
              <el-radio-button :label="0">产品</el-radio-button>
              <el-radio-button :label="1">产品图片</el-radio-button>
<!--              <el-radio-button :label="2">图片压缩包(zip)</el-radio-button>-->
            </el-radio-group>
            <el-link type="primary" style="margin-left: 12px" v-if="type == 0">下载Execl模板</el-link>
          </el-form-item>
          <el-form-item label="导入文件">
            <el-upload
                class="upload-demo"
                drag
                :action="action[type]"
                :accept="accept[type]"
                :on-success="uploadSuccessHandle"
                :multiple="type == 1"
                :data="{role:'admin'}"
                :show-file-list="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip" v-if="type!=0">图片文件名必须是：型号.后缀、型号(1).后缀、型号(1) .后缀、型号-1.后缀</div>
            </el-upload>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <span>产品分类信息不存在时,产品暂时类型:</span>-->
<!--            <el-input style="width: 200px;margin-left: 12px"></el-input>-->
<!--          </el-form-item>-->
        </el-form>
        <div style="text-align: right" v-if="type==0">
          <el-button type="primary" :disabled="!file || tableData.length == 0" @click="saveUpload">保存</el-button>
        </div>
      </el-col>
      <el-col :span="12">
        <el-input type="textarea" v-model="uploadData" :rows="5"></el-input>
      </el-col>
      <el-col>
        <el-table v-if="tableData.length > 0" :data="tableData">
          <el-table-column v-for="(item,index) in tableHeaderOrigin" width="200px">
            <template slot="header" slot-scope="scope">
              <el-select v-model="tableHeaderNewType[index]" clearable>
                <el-option v-for="mapitem in tableFiledMap" :value="mapitem" :disabled="mapitem | AbleFilter(tableHeaderNewType)">{{mapitem}}</el-option>
              </el-select>
            </template>
            <template slot-scope="scope">
              {{scope.row[item]}}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {apiSaveUpload} from "@/request/api";

export default {
  name: "goodsUpload",
  filters:{
    AbleFilter(value,list){
      return list.some(item => {return item == value})
    }
  },
  watch:{
    type(){
      console.log(this.type)
      this.tableData=[]
          this.tableHeaderOrigin=[]
          this.tableHeaderNewType=[]
          this.tableFiledMap={}
      this.file=''
    }
  },
  data() {
    return {
      type:0,
      accept:[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          'image/*',
          '.zip',
      ],
      tableData:[],
      tableHeaderOrigin:[],
      tableHeaderNewType:[],
      tableFiledMap:{},
      file:'',
      action:['https://api.chipdatas.com/goods/tool/import','https://api.chipdatas.com/goods/tool/import-image'],
      uploadData:''
    }
  },
  methods:{
    uploadSuccessHandle(res){
      if(this.type == 0) {
        this.tableFiledMap = res.data.filedMap
        this.tableHeaderOrigin = res.data['header_index'].map(item => {return item.origin})
        this.tableHeaderNewType = res.data['header_index'].map(item => {return item['new_type'][0] == '-'?'':item['new_type']})
        this.tableData = res.data.data
        this.file = res.data.file
      }

      if(this.type == 1) {
        console.log(res)
        this.uploadData = this.uploadData + res.data[0]
      }
    },
    saveUpload(){
      var header = []
      this.tableHeaderOrigin.forEach((item,index) => {
        header.push({
          origin:item,
          new_type:this.tableHeaderNewType[index]
        })
      })
      apiSaveUpload({file:this.file,header}).then(res => {
        console.log(res)
        if(res.code == 200) {
          this.uploadData = this.uploadData + res.data
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
</style>
